import * as React from 'react';
import './services.style.scss';

import Seo from '../components/seo';
import PageTitle from '../components/pagetitle';
import PageContent from '../components/pagecontent';
import ContentServices from '../components/contentservices';
import Cta from '../components/cta';

const IndexPage = () => (
  <main className='site-main'>
    <Seo
      title='From Design to SEO, We Can Do That '
      description='From branding to ranking on search engines, there are lots of pieces to the online puzzle. We can help you put them together.'
    />
    <section className='services section'>
      <PageTitle
        pageTitle='Services'
        pageTagline='Sure, We Can '
        pageHighlight='Do That'
        pageClone='Get Some'
      />
      <PageContent
        heading='We Develop Sites that Offer Users Real Value'
        contentText='Our belief in handmade work ensures that our customers stand out from the competition. We achieve this by conveying stories, emotions and content and using them to create unique experiences.'
      />
    </section>
    <ContentServices />
    <Cta ctaTitle='Need Some Help?' />
  </main>
);

export default IndexPage;
