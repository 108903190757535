import * as React from 'react';

import './contentservices.style.scss';

const ContentServices = () => (
  <section className='content--services'>
    <div>
      <h2>Design</h2>
      <ul>
        <li>Branding</li>
        <li>User Experiences</li>
        <li>Wireframing / Prototyping</li>
        <li>Web Design (Bespoke / 3rd Party Themes)</li>
      </ul>
    </div>
    <div>
      <h2>Development</h2>
      <ul>
        <li>Front-End</li>
        <li>Back-End</li>
        <li>Content Management Systems (CMS)</li>
        <li>E-Commerce Solutions</li>
      </ul>
    </div>
    <div>
      <h2>Optimizations</h2>
      <ul>
        <li>Search Engine (SEO)</li>
        <li>Conversion Rate (CRO)</li>
        <li>Google Certified Analytics Analysis</li>
        <li>On-Going Maintenance</li>
      </ul>
    </div>
  </section>
);

export default ContentServices;
