import * as React from 'react';
import { Link } from 'gatsby';

import './cta.style.scss';

const Cta = ({ ctaTitle }) => (
  <section id='contact' className='cta--contact'>
    <div className='wrapper'>
      <h3>
        {ctaTitle}
        <br />
        Contact us now to start creating your own Experiences.
      </h3>
      <Link
        title='Contacts'
        to='/contact'
        className='btn cta__btn transform-scale-h
                '
      >
        Contact Us
      </Link>
    </div>
  </section>
);

export default Cta;
